import { createReducer, on } from '@ngrx/store';

import { FeedbackSettings } from '@mp/shared/helper-links/feedback/domain';

import { FeedbackActions } from './feedback.actions';

export const feedbackFeatureKey = 'mp-feedback';

export interface FeedbackState {
  feedbackSettings: FeedbackSettings | undefined;
  isLoading: boolean;
}

export const initialState: FeedbackState = {
  feedbackSettings: undefined,
  isLoading: false,
};

export const feedbackReducer = createReducer(
  initialState,
  on(
    FeedbackActions.fetchFeedbackSettings,
    (state): FeedbackState => ({
      ...state,
      feedbackSettings: undefined,
      isLoading: true,
    }),
  ),
  on(
    FeedbackActions.fetchFeedbackSettingsSuccess,
    (state, { feedbackSettings }): FeedbackState => ({
      ...state,
      feedbackSettings,
      isLoading: false,
    }),
  ),
  on(
    FeedbackActions.fetchFeedbackSettingsError,
    (state): FeedbackState => ({
      ...state,
      feedbackSettings: undefined,
      isLoading: false,
    }),
  ),

  on(
    FeedbackActions.updateFeedbackSettings,
    (state): FeedbackState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    FeedbackActions.updateFeedbackSettingsSuccess,
    (state, { feedbackSettings }): FeedbackState => ({
      ...state,
      feedbackSettings,
      isLoading: false,
    }),
  ),
  on(
    FeedbackActions.updateFeedbackSettingsError,
    (state): FeedbackState => ({
      ...state,
      isLoading: false,
    }),
  ),
);

import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { FeedbackSettings } from '@mp/shared/helper-links/feedback/domain';

export const FeedbackActions = createActionGroup({
  source: 'Feedback',
  events: {
    fetchFeedbackSettings: emptyProps(),
    fetchFeedbackSettingsSuccess: props<{ feedbackSettings: FeedbackSettings }>(),
    fetchFeedbackSettingsError: emptyProps(),

    updateFeedbackSettings: props<{ feedbackSettings: FeedbackSettings }>(),
    updateFeedbackSettingsSuccess: props<{ feedbackSettings: FeedbackSettings }>(),
    updateFeedbackSettingsError: emptyProps(),
  },
});
